import React from "react"
import Container from "../components/container"
import ChangingText from "../components/changingText"
import PortofolioCard from "../components/portofolioCard"
import { Helmet } from "react-helmet"


export default class Index extends React.Component {
  render() {
    return (
      <Container>
        <Helmet>

        </Helmet>
        <div className="index-wrapper">

          {/*Heading*/}
          <section id="heading">
            <div className={`hero is-primary is-bold is-fullheight`}>

              <div className={`hero-body`}>
                <div className="container has-text-centered">
                  <p className="title">
                    Hey! my name is <br />
                    <span className="name">Kevin Kent</span><br />
                    and I do
                  </p>
                  <div className="columns is-centered" id="changingText-wrapper">
                    <ChangingText />
                  </div>
                </div>
              </div>

              <div className="hero-foot">
                <div className="is-fullwidth has-text-centered">
                </div>
              </div>
            </div>
          </section>
          {/*/heading*/}

          {/*content body*/}
          <section id="content">

            <div className="content-wrapper">

              {/*Portofolio section*/}
              <div className="section">
                <div className="container">
                  <div className="work columns is-centered mb-4">
                    {/*Portofolio section heading*/}
                    <div className="column is-10" id="#work-heading">
                      <h1 className="title">Projects</h1>
                      <h5 className="subtitle">
                        A selected portofolio of what I've been working on
                      </h5>
                    </div>
                    {/* /Portofolio section heading*/}
                  </div>
                  <div className={`columns is-centered`}>
                    <div className={`column is-10`}>
                      {/*Portofolio Body*/}
                      <div className={`tile is-ancestor`}>
                        <div className={`tile is-vertical is-parent is-6`}>

                          <PortofolioCard title={"NextJS News Aggregator"} desc={"lorem ipsum dolor sit amet"}
                                          imgUrl={"/img/next-news.png"}
                                          url={"https://next-news.kevinkent1505.vercel.app/"} />
                          <PortofolioCard title={"CIVL1160 Truss Calculator"} desc={"lorem ipsum dolor sit amet"}
                                          imgUrl={"#"}
                                          url={"https://github.com/kevinkent1505/TrussCalculator-CIVL1160"} />
                        </div>
                        <div className={`tile is-vertical is-parent`}>
                          <PortofolioCard title={"COMP4461 Project 1"} desc={"lorem ipsum dolor sit amet"}
                                          imgUrl={"/img/eldhub-menu.png"} url={"/projects/comp4461-project1"} />
                        </div>

                      </div>
                      <div className={`tile is-ancestor`}>
                        <div className={`tile is-vertical is-parent is-8`}>
                          <PortofolioCard title={"COMP4461 Project 2"} desc={"lorem ipsum dolor sit amet"}
                                          imgUrl={"/img/slack-ui-project2.png"} url={"/projects/comp4461-project2"} />

                        </div>
                        <div className={`tile is-vertical is-parent is-4`}>
                          <PortofolioCard title={"COMP4461 Project 3"} desc={"lorem ipsum dolor sit amet"}
                                          imgUrl={"/img/hubs.png"} url={"/projects/comp4461-project3"} />
                        </div>
                      </div>
                      {/* /Portofolio body*/}
                    </div>
                  </div>
                </div>
              </div>
              {/* / Portofolio section*/}

            </div>
          </section>
          {/* /content body*/}

        </div>

      </Container>
    )
  }
}
