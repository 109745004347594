import React from "react"
import { TweenLite, TweenMax, TimelineLite } from "gsap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default class PortofolioCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpanded: false
    }
    this.pCard = null
    this.pCardTween = null
  }

  componentDidMount() {

  }


  calculatePosition(element) {
    let root = document.documentElement
    let body = document.body
    let rect = element.getBoundingClientRect()

    let scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0
    let scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0

    let clientTop = root.clientTop || body.clientTop || 0
    let clientLeft = root.clientLeft || body.clientLeft || 0

    return {
      top: Math.round(rect.top + scrollTop - clientTop),
      left: Math.round(rect.left + scrollLeft - clientLeft),
      height: rect.height,
      width: rect.width
    }
  }


  render() {
    const {imgUrl =  `url('https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/36385/desktop-computer-website-clipart-md.png')`} = this.props;
    return (
      <>
          <AniLink to={this.props.url} className={`portofolioCard tile is-child box`}
               style={{
                 backgroundImage: `url(${this.props.imgUrl!= "#" ? this.props.imgUrl: "https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/36385/desktop-computer-website-clipart-md.png"})`,
                 backgroundSize: "cover",
                 backgroundRepeat: "no-repeat"
               }}>

            <div className={`content bottom-text`}>
              <p className={`title is-12 has-text-centered  has-text-white`}>{this.props.title}</p>
            </div>

          </AniLink>
      </>
    )
  }
}