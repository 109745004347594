import React from "react"

export default class ChangingText extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      desc: ["Web Development", "UI/UX Design", "MongoDB", "React", "NodeJS", "express.js", "Spring Boot", "SQL"]
    }
  }

  render() {
    return (
      <div className="changingText column is-half columns is-centered is-mobile">
          {
            this.state.desc.map(function(item, index) {
                return <p key={index} className={`changedText title`} id={`changedText` + (index + 1)}>
                  {item}
                </p>
              }
            )
          }
      </div>
    )
  }
}